.parent-container {
  position: relative;
  height: 100%;
  width: 100%;
  /* background: #ccdbd8; */
}

.parent-container.wallet {
  background: none;
}

.landscape-container {
  /* background-color: rgb(0, 0, 0, 0.8); */
  width: 100%;
  padding-top: 136.1%;
  position: relative;
  z-index: 0;
  border-radius: 20px;
  border: 1px solid #ccdbd8;
  overflow: hidden;
}

.landscape-container img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
.evolving-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
.anim-evolv-div {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 50px;
  z-index: 1000;
  text-align: center;
}
.anim-evolv-button {
  border: none;
  height: 50px;
  background-color: transparent;
}
.anim-evolv-button:hover {
  cursor: pointer;
}
.anim-evolv-button img {
  position: relative;
}
.anim-evolv-button:focus {
  outline: none;
}

.landscape-container video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-btn-container {
  z-index: 1220;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* .play-btn-container:hover {
    opacity: 0.8;
  } */

.play-btn-container .play-btn {
  position: absolute;
  top: 10px;
  right: unset;
  left: 10px;
  bottom: 0;
  height: 30px;
  width: 30px;
}

.spinner-container {
  z-index: 5;
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.token-shadow {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: -60px;
  pointer-events: none;
}
.token-shadow img {
  width: 100%;
}

.landscape-container .fullscreen-btn-container {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 0;
  padding: 0;
  background: transparent;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.landscape-container .fullscreen-btn-container:hover {
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
}
.landscape-container .fullscreen-btn {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  padding: 0;
  background: black;
  margin: auto;
}
.landscape-container .fullscreen-btn:hover {
  border-color: #ccdbd8;
}
.landscape-container .fullscreen-btn:active {
  opacity: 0.8;
}
.landscape-container .fullscreen-btn img {
  height: 24px;
  width: 24px;
  margin: auto;
}

.token-id {
  color: white;
  font-family: "BebasNeue";
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4px;
}

.nft-id {
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  color: white;
  font-family: "LevelUp";
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4px;
}
.nft-container {
  /* background-color: rgb(0, 0, 0, 0.8); */
  width: 100%;
  padding-top: 136.1%;
  position: relative;
  z-index: 0;
  /* border-radius: 20px; */
  /* border: 1px solid #ccdbd8; */
  overflow: hidden;
}

.nft-container img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nft-container video {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-btn-container {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* .play-btn-container:hover {
    opacity: 0.8;
  } */

.play-btn-container .play-btn {
  position: absolute;
  top: 10px;
  right: unset;
  left: 10px;
  bottom: 0;
  height: 30px;
  width: 30px;
}

.spinner-container {
  z-index: 5;
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.token-shadow {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: -60px;
  pointer-events: none;
}
.token-shadow img {
  width: 100%;
}

.nft-container .fullscreen-btn-container {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 0;
  padding: 0;
  background: transparent;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.nft-container .fullscreen-btn-container:hover {
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
}
.nft-container .fullscreen-btn {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  padding: 0;
  background: black;
  margin: auto;
}
.nft-container .fullscreen-btn:hover {
  border-color: #ccdbd8;
}
.nft-container .fullscreen-btn:active {
  opacity: 0.8;
}
.nft-container .fullscreen-btn img {
  height: 24px;
  width: 24px;
  margin: auto;
}

.token-id {
  color: white;
  font-family: "BebasNeue";
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4px;
}

.nft-id {
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  color: white;
  font-family: "LevelUp";
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 4px;
}
