.e-container {
  height: 100%;
  background-color: white;
}

:not(:root):fullscreen,
:not(:root):-webkit-full-screen {
  object-fit: contain !important;
}

.error-container {
  background-color: rgba(255, 8, 8, 0.8);
  width: 100%;
  padding-top: 137.2%;
  position: relative;
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;
}

.error-container .text {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  color: white;
  font-family: "BebasNeue";
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
}

.error-container .text-exclamation {
  font-size: 100px;
}

.e-spinner-container {
  height: 100%;
  width: 100%;
}
.e-spinner-container .la-ball-8bits,
.e-spinner-container .la-timer,
.e-spinner-container svg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-top: auto;
  margin-bottom: auto;
}
