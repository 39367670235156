.details-slide {
  position: absolute;
  max-height: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  transform-origin: top right;
  z-index: 1001;
  overflow: hidden;
  transform: scale(0);
  transition: all 0.4s ease-in-out;
  opacity: 0;
}

.details-slide.redeemed .details-content {
  background: rgba(0, 0, 0, 0.8);
}

.details-content {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1rem;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.details-content a {
  text-decoration: none;
  color: #a53dff;
}

.details-slide .details-content img {
  position: relative;
  max-height: 30px;
  max-width: 30px;
  margin-bottom: 20px;
}

.details-content h3 {
  color: #fff;
  font-size: 1.2em;
  text-transform: capitalize;
  font-family: "Poppins";
  margin-block-start: 0;
  margin-block-end: 0;
}
.details-slide.redeemed .details-content h3,
.details-slide.redeemed .details-content p {
  color: white;
  text-shadow: black;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 50%);
}
.details-content p {
  font-family: "Poppins";
  /* text-transform: uppercase; */
  margin-top: 6px;
  color: #ccc;
  font-size: 0.9em;
  width: 100%;
}

.details-content p strong {
  color: #fff;
  font-weight: 600;
}

.details-on .details-slide {
  transform: scale(1);
  opacity: 1;
}
.trait-popup {
  z-index: 1000;
}
.trait-popup .trait-btn,
.trait-popup .redeemed-btn {
  z-index: 1000;
  transition: all 0.4s ease-in-out;
}
.trait-popup .trait-btn img {
  height: 70%;
  width: 70%;
  margin: auto;
}
.details-on .redeemed-btn,
.details-on .trait-btn {
  opacity: 0;
}

.details-on .close-btn img {
  width: 16px !important;
  height: 16px !important;
  filter: none;
  top: 0;
  right: 0;
}

.details-slide .close-btn {
  position: fixed;
  height: 16px;
  width: 16px;
  top: 4%;
  right: 4%;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

a.trait-btn,
a.redeemed-btn {
  position: absolute;
  background: #000;
  border-radius: 4px;
  font-size: 1rem;
  padding: 0;
  height: 2rem;
  width: 2rem;
  font-weight: 800;
  color: #fff !important;
  top: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
  display: flex;
}

a.trait-btn:hover,
a.redeemed-btn:hover {
  background: #000;
  border-color: #ccdbd8;
  opacity: 0.8;
}

a.trait-btn.details-on,
a.redeemed-btn.details-on {
  opacity: 0;
}
